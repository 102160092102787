import * as React from 'react'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { marks } from '../Constants/questions'

export type CustomSliderProps = {
    id: string
    value: number
    setValue: (value: number) => void
    customWidth?: string
    smallSize?: boolean
}

export const CustomSlider = ({
    id,
    value,
    setValue,
    customWidth,
    smallSize,
}: CustomSliderProps) => {
    return (
        <Box
            sx={{
                width: customWidth ? customWidth : '70%',
                justifyContent: 'center',
                color: 'primary',
            }}
        >
            <Slider
                size={smallSize ? 'small' : 'medium'}
                value={value}
                onChange={(event, value) => {
                    setValue(value as number)
                }}
                valueLabelDisplay="auto"
                step={1}
                marks={marks}
                min={1}
                max={10}
            />
        </Box>
    )
}
