// Code intended to be shared by Client and Server

export const translationApiKey = 'FVCbRm7ieRvn4Rg6mp2loQ'
export const translationLoadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${translationApiKey}`
export const emailSheetUrl = 'https://sheet.best/api/sheets/3fea3177-8e8a-49d7-b71c-1af58cefbfd8';

export const GRAPH_GENERATOR_ULR = 'https://api.ocsdiagnostics.com/generate';

export const supportedLngs = ['en', 'he', 'es', 'ru', 'fr'];


export async function fetchPostObject(url: string, obj: any) {
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(obj),
	});
	try {
		const data = await res.json();
		return data;
	} catch (err) {
		console.error(`Error while parsing res.json(). Original text:`);
		console.error(res.text());
		console.error(err);
		throw err;
	}
}

export const stringToFilename = (s: string) => s.normalize('NFKD').replace(/[^\w- .]/g, '');
