export const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },

    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
    {
        value: 8,
    },

    {
        value: 9,
    },
    {
        value: 10,
        label: '10',
    },
]

export const q1Answers = [
    {
        id: '1',
        value: 'No',
    },
    {
        id: '2',
        value: 'Yes',
    },
]

export const q2Answers = [
    {
        id: '1',
        value: 'Incremetally',
    },
    {
        id: '2',
        value: 'Continuously',
    },
]

export const q3Answers = [
    {
        id: '1',
        value: 'UnclearAndUnsupportive',
    },
    {
        id: '2',
        value: 'UnclearButSupportive',
    },
    {
        id: '3',
        value: 'ClearAndSupportive',
    },
    {
        id: '4',
        value: 'ClearButUnsupportive',
    },
]

export const q4Answers = [
    {
        id: '1',
        value: 'VeryUnlikely',
    },
    {
        id: '2',
        value: 'Unlikely',
    },
    {
        id: '3',
        value: 'Likely',
    },
    {
        id: '4',
        value: 'VeryLikely',
    },
]
