import {
    FormControlLabel,
    FormLabel,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import { jsPDF } from 'jspdf'
import * as React from 'react'
import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas';

import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'
import emailjs from 'emailjs-com'
import ReactS3Client from 'react-aws-s3-typescript'
import { Buffer } from 'buffer'

import type { Schema } from '../../amplify/data/resource'
import { Amplify } from "aws-amplify"
import { generateClient } from "aws-amplify/api"
import outputs from "../amplify_outputs.json"

import { fetchPostObject, GRAPH_GENERATOR_ULR, stringToFilename } from '../shared'
import { q2Answers, q3Answers, q4Answers } from '../Constants/questions'
import { CustomSlider } from './CustomSlider'
import { ReportPage1, ReportPage2, ReportPage3 } from './Report/Report'
import { useFormStyles } from './styles'


Amplify.configure(outputs);
const client = generateClient<Schema>();


const s3config = {
    bucketName: 'ocs-diagnostics-reports',
    region: 'eu-west-3',
    accessKeyId: 'AKIA3J4VRK67D4A577EC',
    secretAccessKey: '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',
}

const isEmail = (email: string) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
const getCountry = (lan: string) => {
    if (lan === 'he') return 'IL'
    if (lan === 'es') return 'ES'
    if (lan === 'fr') return 'FR'
    return 'US'
}

const getLanguage = (lan: string) => {
    if (lan === 'IL') return 'he'
    if (lan === 'ES') return 'es'
    if (lan === 'FR') return 'fr'
    return 'en'
}

export const Form = () => {
    console.log('Form re-render');

    const { classes } = useFormStyles()
    const { t, i18n } = useTranslation()
    // document.body.dir = i18n.dir() // ???
    const [Q1, setQ1] = React.useState('No' as string)
    const [Q2, setQ2] = React.useState(q2Answers[0].value as string)
    const [Q3, setQ3] = React.useState(q3Answers[0].value as string)
    const [Q4, setQ4] = React.useState(q4Answers[0].value as string)
    const [Q5, setQ5] = React.useState(5)
    const [Q6, setQ6] = React.useState(5)
    const [Q7, setQ7] = React.useState(5)
    const [Q8, setQ8] = React.useState(5)
    const [Q9, setQ9] = React.useState(5)
    const [Q10, setQ10] = React.useState(5)
    const [Q11, setQ11] = React.useState(5)
    const [Q12, setQ12] = React.useState(5)
    const [Q13, setQ13] = React.useState(5)
    const [Q14, setQ14] = React.useState(5)
    const [Q15, setQ15] = React.useState(5)
    const [Q16, setQ16] = React.useState(5)
    const [QEvents, setQEvents] = React.useState(1)
    const [QName, setQName] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSendingEmail, setIsSendingEmail] = React.useState(false)
    const [isGeneratingReport, setIsGeneratingReport] = React.useState(false)
    const [imageSrc, setImageSrc] = React.useState('')
    const [imageDataUrl, setImageDataUrl] = React.useState<string | null>(null);

    const [email, setEmail] = React.useState('');
    const [reportUrl, setReportUrl] = React.useState<string | null>(null);

    const [smallCircleArea, setSmallCircleArea] = React.useState(0)
    const [bigCircleArea, setBigCircleArea] = React.useState(0)
    const [strategyArea, setStrategyArea] = React.useState(0)
    const [cultureArea, setCultureArea] = React.useState(0)
    const [structureArea, setStructureArea] = React.useState(0)
    const [dataArea, setDataArea] = React.useState(0)
    const [vmvPosition, setVmvPosition] = React.useState('')
    const [vmvSector, setVmvSector] = React.useState('')
    const [emailSent, setEmailSent] = React.useState(false)
    const [emailError, setEmailError] = React.useState(false)
    const [reportGenerated, setReportGenerated]= React.useState(false)
    const graphRef = React.useRef(null)

    /*

    const saveEmailAddress = async () => {
        if (emailSent) {
            return
        }
        if (isEmail(email)) {
            setIsSendingEmail(true)
            setEmailError(false)

            try {
                await fetchPostObject(emailSheetUrl, { email })
                // ??? How does sheet.best return errors? What if response code is not 200? 
                setEmailSent(true)
                setIsSendingEmail(false)
                //send feedback email
            } catch (error) {
                console.warn(error);
                setEmailSent(true); // !!! Temp, remove
                setIsSendingEmail(false);
            }
        } else {
            setEmailError(true)
        }
    }
    
    // document.dir = 'rtl' // ???

    const uploadFile = async (arrayBuffer: ArrayBuffer) => {
        const pdfBuffer = Buffer.from(new Uint8Array(arrayBuffer))
        var blob = new Blob([pdfBuffer], {
            type: 'application/pdf',
        })
        var file = new File([blob], `pdf`)
        const s3 = new ReactS3Client(s3config)
        try {
            const res = await s3.uploadFile(
                file,
                `${QName}-OcsReport-${Date.now()}`
            )

        } catch (exception) {
            console.log(exception)
        }
    }

    const generateGraph = async () => {
        console.log({QName,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,QEvents: Number(QEvents)})

        const data = await fetchPostObject(
            GRAPH_GENERATOR_ULR,
            {QName,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,QEvents: Number(QEvents)}
        );
        console.log("graph generation result data:", data);
        
        setImageSrc(data.graphFileName);
        setBigCircleArea(data.bigCircleArea);
        setSmallCircleArea(data.smallCircleArea);
        setStrategyArea(data.strategyArea);
        setCultureArea(data.cultureArea);
        setStructureArea(data.structureArea);
        setDataArea(data.dataArea);
        setVmvPosition(data.vmvPosition);
        setVmvSector(data.vmvSector);
    
        const imageRes = await fetch(`https://ocs-diagnostics-graphs.s3.eu-west-3.amazonaws.com/${data.graphFileName}`);
        const imageBlob = await imageRes.blob();
        const imDataUrl = URL.createObjectURL(imageBlob);
        setImageDataUrl(imDataUrl);
    }

    const sleep = (seconds: number) => new Promise((resolve, _) => setTimeout(resolve, 1000 * seconds));

    const generateReport = async () => {
        setIsGeneratingReport(true)
        var doc = new jsPDF('p', 'pt', 'a4')

        await sleep(2);
        // const data = await domtoimage.toPng(document.getElementById('pdfPage1')!);
        // This line ^ seems to be necessary to pre-load the image
        // but of course that is an awkward solution
        // !!! Fix ^
        const imgDataPage1 = await domtoimage.toPng(document.getElementById('pdfPage1')!);
        const imgDataPage2 = await domtoimage.toPng(document.getElementById('pdfPage2')!);
        const imgDataPage3 = await domtoimage.toPng(document.getElementById('pdfPage3')!);

        const page1Canvas = await html2canvas(document.getElementById('pdfPage1')!);
        document.body.appendChild(page1Canvas);
        console.log('Canvas size:', page1Canvas.width, page1Canvas.height);

        var page1 = new Image()
        var page2 = new Image()
        var page3 = new Image()
        page1.src = imgDataPage1
        page2.src = imgDataPage2
        page3.src = imgDataPage3
        doc.addImage(
            page1,
            'PNG',
            0,
            0,
            595,
            841,
            '',
            'FAST'
        )
        doc.addPage()
        doc.addImage(
            page2,
            'PNG',
            0,
            0,
            595,
            841,
            '',
            'FAST'
        )
        doc.addPage()
        doc.addImage(
            page3,
            'PNG',
            0,
            0,
            595,
            841,
            '',
            'FAST'
        )
        doc.save(`${QName}-OcsReport`)
        //Upload to S3
        const arrayBuffer =
            doc.output('arraybuffer')
        doc.close()

        //uploadFile(arrayBuffer)

        setIsGeneratingReport(false)
        setReportGenerated(true)
        emailjs.send(
            'service_pywd7in',
            'template_aij6vqq',
            { email: email },
            'pVpaOglPxlg_MEMdL'
        )
    }

    */

    // Based on: https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
    function downloadFile(href: string, filename: string) {
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = href;
        link.download = filename;

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
            link.parentNode!.removeChild(link);
        }, 0);
    }

    
    const downloadAsFilename = `${stringToFilename(QName)}-OcsReport`;
    const sendEmailAndGenerateReport = async () => {
        setReportUrl(null);
        setIsLoading(true);
        setIsGeneratingReport(true);
        
        const resp = await client.queries.someScreenshot({
            lng: i18n.language,
            pageDirection: i18n.dir(),
            QName,
            Q1, Q2, Q3, Q4,
            Q5, Q6, Q7, Q8, Q9, Q10, Q11, Q12, Q13, Q14, Q15, Q16,
            QEvents,
            email,
        });
        console.info(resp.data);
        
        setIsGeneratingReport(false);
        setIsLoading(false);
        setReportUrl(resp.data);
        
        downloadFile(resp.data!, downloadAsFilename);

        /*
        console.log("saving email addresses...")
        await saveEmailAddress(); 
        
        setIsLoading(true)

        console.log("generating graph...")
        await generateGraph();

        console.log("generating report...")
        await generateReport();

        setIsLoading(false)
        */
    }

    const changeLanguageHandler = (code: string) => {
        const languageValue = getLanguage(code)
        i18next.changeLanguage(languageValue)
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <Box className={classes.headerContainer}>
                    <h2>{t('Title')}</h2>
                    <ReactFlagsSelect
                        selected={getCountry(i18next.language)}
                        countries={['US', 'ES', 'IL', 'FR']}
                        customLabels={{
                            US: 'English',
                            ES: 'Español',
                            IL: 'עִברִית',
                            FR: 'Français',
                        }}
                        fullWidth={false}
                        onSelect={(code) => changeLanguageHandler(code)}
                    />
                    <h3>
                        <b>{t('Subtitle1')}</b>
                    </h3>{' '}
                    <p>{t('Description')}</p>{' '}
                    <a
                        href="https://my.visme.co/view/310p7ozq-m3x58kew18nm5krp"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button variant="outlined" id="white-paper-button">
                            {t('ButtonWhitePaper')}
                        </Button>
                    </a>
                    <br />
                    <br />
                    <Box>
                        {' '}
                        <b>{t('Subtitle2')}</b>
                        <Box className={classes.callToActionButtonsContainer}>
                            <Button
                                variant="contained"
                                className={classes.callToActionButton}
                                sx={{ marginRight: '10px' }}
                                onClick={() => {
                                    window.open(
                                        'mailto:elena@purplesuncorp.com?subject=Workshop'
                                    )
                                }}
                                id="workshop-button"
                            >
                                {t('ButtonJoinWorkshop')}
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.callToActionButton}
                                onClick={() => {
                                    window.open(
                                        'mailto:elena@purplesuncorp.com?subject=Workshop'
                                    )
                                }}
                                id="book-consulting-button"
                            >
                                {t('ButtonBookConsulting')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.formContainer}>
                {/*
                <Button onClick={async () => {
                    const resp = await client.queries.someReact({ Q5, Q6 });
                    console.info(resp.data);
                }}>Send React Request</Button>
                <Button onClick={async () => {
                    const resp = await client.queries.someScreenshot({
                        lng: i18n.language,
                        pageDirection: i18n.dir(),
                        QName,
                        Q1, Q2, Q3, Q4,
                        Q5, Q6, Q7, Q8, Q9, Q10, Q11, Q12, Q13, Q14, Q15, Q16,
                        QEvents,
                        email,
                    });
                    console.info(resp.data);
                }}>Upload to Bucket</Button>
                <img src={imageDataUrl ?? ''} />
                */}

                <Box id="boxqName" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('CompanyName')}
                    </FormLabel>
                    <TextField
                        id="qName"
                        value={QName}
                        onChange={(e: any) => setQName(e.target.value)}
                    />
                </Box>
                <Box id="boxq1" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question1')}
                    </FormLabel>
                    <Select
                        id="q1"
                        value={Q1}
                        onChange={(event: any) => {
                            setQ1(event.target.value as string)
                        }}
                        className={classes.q1Select}
                    >
                        <MenuItem value="No">{t('No')}</MenuItem>
                        <MenuItem value="Yes">{t('Yes')}</MenuItem>
                    </Select>
                </Box>
                <Collapse in={Q1 === 'Yes'}>
                    <Box>
                        <Box id="boxq2" className={classes.question}>
                            <FormLabel className={classes.label}>
                                {t('question2')}
                            </FormLabel>
                            <RadioGroup
                                id="q2RadioGroup"
                                value={Q2}
                                onChange={(event: any) => {
                                    setQ2(event.target.value as string)
                                }}
                            >
                                {q2Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q2${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="boxq3" className={classes.question}>
                            <FormLabel className={classes.label}>
                                {t('question3')}
                            </FormLabel>
                            <RadioGroup
                                id="q3RadioGroup"
                                value={Q3}
                                onChange={(event: any) => {
                                    setQ3(event.target.value as string)
                                }}
                            >
                                {q3Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q3${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>
                        <Box id="boxq4" className={classes.question}>
                            <FormLabel className={classes.label}>
                                {t('question4')}
                            </FormLabel>
                            <RadioGroup
                                id="q4RadioGroup"
                                value={Q4}
                                onChange={(event: any) => {
                                    setQ4(event.target.value as string)
                                }}
                            >
                                {q4Answers.map((answer, index) => {
                                    return (
                                        <FormControlLabel
                                            value={answer.value}
                                            control={<Radio color="primary" />}
                                            label={t(answer.value)}
                                            key={`q4${index}`}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </Box>

                        {Q2 === q2Answers[1].value && (
                            <Box id="qEvents" className={classes.question}>
                                <FormLabel className={classes.label}>
                                    {t('questionEvents')}
                                </FormLabel>
                                <Input
                                    type="number"
                                    id="qEventsInput"
                                    value={QEvents}
                                    onChange={(event: any) => {
                                        const value = event.target
                                            .value as unknown as number
                                        if (value > 0) setQEvents(value)
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Collapse>

                <Box id="boxq5" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question5')}
                    </FormLabel>
                    <CustomSlider id="q5" value={Q5} setValue={setQ5} />
                </Box>
                <Box id="boxq6" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question6')}
                    </FormLabel>
                    <CustomSlider id="q6" value={Q6} setValue={setQ6} />
                </Box>
                <Box id="boxq7" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question7')}
                    </FormLabel>
                    <CustomSlider id="q7" value={Q7} setValue={setQ7} />
                </Box>
                <Box id="boxq8" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question8')}
                    </FormLabel>
                    <CustomSlider id="q8" value={Q8} setValue={setQ8} />
                </Box>
                <Box id="boxq9" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question9')}
                    </FormLabel>
                    <CustomSlider id="q9" value={Q9} setValue={setQ9} />
                </Box>
                <Box id="boxq10" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question10')}
                    </FormLabel>
                    <CustomSlider id="q10" value={Q10} setValue={setQ10} />
                </Box>
                <Box id="boxq11" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question11')}
                    </FormLabel>
                    <CustomSlider id="q11" value={Q11} setValue={setQ11} />
                </Box>
                <Box id="boxq12" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question12')}
                    </FormLabel>
                    <CustomSlider id="q12" value={Q12} setValue={setQ12} />
                </Box>
                <Box id="boxq13" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question13')}
                    </FormLabel>
                    <CustomSlider id="q13" value={Q13} setValue={setQ13} />
                </Box>
                <Box id="boxq14" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question14')}
                    </FormLabel>
                    <CustomSlider id="q14" value={Q14} setValue={setQ14} />
                </Box>
                <Box id="boxq15" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question15')}
                    </FormLabel>
                    <CustomSlider id="q15" value={Q15} setValue={setQ15} />
                </Box>
                <Box id="boxq16" className={classes.question}>
                    <FormLabel className={classes.label}>
                        {t('question16')}
                    </FormLabel>
                    <CustomSlider id="q16" value={Q16} setValue={setQ16} />
                </Box>

                <Box className={classes.submit}>
                    {!emailSent && (
                        <div>
                            <FormLabel className={classes.label}>
                                {t('EmailLabel')}
                            </FormLabel>

                            <br />
                            <TextField
                                id="qEmail"
                                type="email"
                                value={email}
                                onChange={(e: any) => {
                                    setEmail(e.target.value)
                                }}
                                error={emailError}
                                helperText={emailError && t('IncorrectEmail')}
                                className={classes.sendEmailTextField}
                            />
                        </div>
                    )}
                    {!(isSendingEmail || isGeneratingReport || isLoading) && <Button
                            disabled={!isEmail(email)}
                            variant="outlined"
                            className={classes.sendEmailButton}
                            onClick={sendEmailAndGenerateReport}
                            id="download_report"
                        >
                            {t('Generate')} {t('report')}
                        </Button>
                    }
                    { reportUrl !== null &&
                        <Box>
                            {t('thankYouAndSurvey')} <br />
                            <a href={reportUrl} download={downloadAsFilename}>{t('downloadReport')}</a>
                        </Box>
                    }
                    {(isSendingEmail || isGeneratingReport || isLoading) && (
                        <Box className={classes.loader}>
                            {isGeneratingReport && <Box> {t('generatingReport')} </Box>}
                            {/* {isLoading && <Box> {t('processing')} </Box>} ??? Necessary? */}
                            <CircularProgress style={{ alignSelf:'center', marginTop:'35px' }} />
                        </Box>
                    )}
                    {!reportGenerated && imageDataUrl &&
                        <img
                            src={imageDataUrl ?? ''}
                            //src={`https://ocs-diagnostics-graphs.s3.eu-west-3.amazonaws.com/${imageSrc}`}
                            alt='graph' style={{width:'100%'}}/>
                    }
                </Box>
              
                <div ref={graphRef} />
            </Box>

            <Box className={classes.footerContainer}>
                <Box>
                    <span>{new Date().getFullYear()}</span> © {t('AllRightsReserved')}
                </Box>
                <Box>
                    {t('builtBy')}{' '}
                    <a
                        href="https://www.linkedin.com/in/andrei-tudorica-661a22b9/"
                        className={classes.footerName}
                    >
                        Andrei Tudorica
                    </a>
                </Box>
            </Box>

            {/*
            <div className={classes.reportMask}>
                <div className={classes.reportPageMask}>
                    <div id="pdfPage1">
                        <ReportPage1
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphSrc={imageDataUrl}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
                <div className={classes.reportPageMask}>
                    <div id="pdfPage2">
                        <ReportPage2
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphSrc={imageDataUrl}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
                <div className={classes.reportPageMask}>
                    <div id="pdfPage3">
                        <ReportPage3
                            QName={QName}
                            Q1={Q1}
                            Q2={Q2}
                            Q3={Q3}
                            Q4={Q4}
                            Q5={Q5}
                            Q6={Q6}
                            Q7={Q7}
                            Q8={Q8}
                            Q9={Q9}
                            Q10={Q10}
                            Q11={Q11}
                            Q12={Q12}
                            Q13={Q13}
                            Q14={Q14}
                            Q15={Q15}
                            Q16={Q16}
                            QEvents={QEvents}
                            graphSrc={imageDataUrl}
                            smallCircleArea={smallCircleArea}
                            bigCircleArea={bigCircleArea}
                            strategyArea={strategyArea}
                            cultureArea={cultureArea}
                            structureArea={structureArea}
                            dataArea={dataArea}
                            vmvPosition={vmvPosition}
                            vmvSector={vmvSector}
                        />
                    </div>
                </div>
            </div>
            */}
        </Box>
    )
}
